import {Typography} from "@material-ui/core"
import React from "react"
import { makeStyles } from '@material-ui/core/styles'

import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles({
  root:{
      textAlign:"center",
      paddingTop: "1rem",
      margin:"auto"
  },
  title: {
      fontSize: 50,

  }

})


const NotFoundPage = () => {

  const classes = useStyles()

  return(<Layout>
    <SEO title="404: Siden finnes ikke" />
    <div className={classes.root}>
    <Typography className={classes.title}>Siden finnes ikke</Typography>
    </div>
    
  </Layout>)
}

export default NotFoundPage
